import React, { useEffect, useState } from 'react';
import { useQuery,} from 'react-query';
import { useParams } from 'react-router-dom';
import { Recomendation, MenuFooter } from '../components';
import { ReactComponent as LoadingSpinner } from './../assets/spinner.svg';
import ReactGA from "react-ga4";
import './../assets/css/menu-page.css'

export const Menu = () => {
    const { id } = useParams();
    const [selectedCategory,setSelectedCategory] = useState();

    //Initialize GA4
    ReactGA.initialize("G-JG9BQ44SSM");

    const SendAnalytics = () => {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
      });
    }
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // reportWebVitals(SendAnalytics);
  

  const {isLoading, error, data} = useQuery({
    queryKey: ['usersData'],
    queryFn: () =>
      fetch(`https://olla-backend.onrender.com/api/place/single?id=${id}`).then(res => res.json()),
  });

  useEffect(() => {
    if(data){
        setSelectedCategory(data?.menuCategories?.[0])
    }
  }, [data])


  if (isLoading) return <div style={{height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <LoadingSpinner style={{width: 70, height:70, alignSelf: 'center'}}/>
    </div>;

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <div>
        <img  src={data?.banner} className="banner" />

        { data?.withoutVat ? <div style={{textAlign: 'center', border: '1px solid brown', marginTop: 10, marginLeft: '5%', marginRight: '5%', padding: 5, borderRadius: 5}}>
          <h4 style={{fontSize: 18, fontWeight: 'normal', color: 'brown'}}>All Prices are Subjected to 15% VAT</h4>
        </div>
         : null}
        {/* <Recomendation /> */}

        <main className="container" style={{marginTop: 20}}>
            <h3 style={{fontSize: 'revert'}}>{data?.name}</h3>
            <div className="buttons">  
            {data?.menuCategories?.map(category => (
                <button key={category.key} 
                className={category?._id === selectedCategory?._id ? 'button-active' : 'button'} 
                onClick={() => {setSelectedCategory(category)}}>{category.name}</button>
            ))}
            </div>

        
            <div className="menu">
                <h6 className="menu-group-heading">{selectedCategory?.name}</h6>
                <div className="menu-group">
            
                {data?.menus?.[0]?.[selectedCategory?.key]?.map(menu => (
                    <div key={menu?.name} className="menu-item">
                        <img
                        src="https://dummyimage.com/200x200/c5c7c7/c5c7c7.jpg"
                        alt="Black Placeholder Image"
                        className="menu-item-img"
                        />
                        <div className="menu-item-text">
                        <h6 className="menu-item-heading">
                            <span className="menu-item-name">{menu?.name}</span>
                            <span className="menu-item-price">{menu?.price} birr</span>
                        </h6>
                        <p className="menu-item-desc">{menu?.description} </p>
                        </div>
                    </div>
                ))}

                </div>
            </div>
        </main>

        <MenuFooter />
    </div>
  );
}
